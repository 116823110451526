<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <div class="view-box">
        <p style="font-size:27pt; line-height:175%; margin:0pt 0pt 10pt; text-align:center"><a
            name="_055-1599121612466"></a><span
            style="color:rgb(51, 51, 51);font-size:27pt; font-weight:bold">应用性能标准</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_555-1599470468477"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_491-1599470468478"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_173-1599470468506"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="1"><a name="_957-1599470468508"></a><span
            style="color:#409eff;font-size:17pt; font-weight:bold">一、背景</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_287-1599470468509"></a><span
            style="color:#595959;font-size:12pt">用户对Android应用的性能体验敏感度和要求越来越高，为给用户提供更极致的应用使用体验，本标准旨在构建一套Android应用性能测试衡量标准与方法，牵引生态内所有应用的性能质量改进，共同构建良好的应用生态性能体验。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_074-1599470581756"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="2"><a name="_991-1599470468512"></a><span
            style="color:#409eff;font-size:17pt; font-weight:bold">二、适用范围</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_520-1599470468513"></a><span
            style="color:#595959;font-size:12pt">本标准适用于Android平台各类应用的性能衡量与判断。应用类型涉及：视频音乐、通讯社交、摄影美图、新闻阅读、购物优惠、实用工具、教育学习、系统工具、金融理财、旅游出行、娱乐消遣、育儿母婴、健康美容、效率办公等,包含但不限于OPPO，VIVO，MI应用商店上架的所有应用。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_328-1599470582924"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="3"><a name="_728-1599470468517"></a><span
            style="color:#409eff;font-size:17pt; font-weight:bold">三、术语、定义、缩略语</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_581-1599470823185"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="3-1"><a name="_066-1599470468521"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">3.1 应用冷启动时间</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_070-1599470664465"></a><span
            style="color:#595959;font-size:12pt">应用冷启动时间，即系统从被测应用的Application创建开始至进入应用主界面的时间。</span></p>
        <p style="margin-bottom:10pt;margin-top: 10pt;" class="nav-title" id="3-2"><a name="_790-1599470468524"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">3.2 界面帧率</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_097-1599470468525"></a><span
            style="color:#595959;font-size:12pt">界面帧率，即应用界面变化过程每秒刷新的平均帧率和连续丢帧表现。</span></p>
        <p style="margin-bottom:10pt;margin-top: 10pt;" class="nav-title" id="3-3"><a name="_270-1599470468526"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">3.3 界面过度绘制</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_020-1599470468526"></a><span
            style="color:#595959;font-size:12pt">界面过度绘制，即屏幕上的某个像素在同一帧的时间内被绘制了多次。</span></p>
        <p style="margin-bottom:10pt;margin-top: 10pt;" class="nav-title" id="3-4"><a name="_652-1599470468527"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">3.4 严格模式检查</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_051-1599470468528"></a><span
            style="color:#595959;font-size:12pt">严格模式检查，检查应用各界面操作时是否出现主线程耗时长的情况。</span></p>
        <p style="margin-bottom:10pt;margin-top: 10pt;" class="nav-title" id="3-5"><a name="_728-1599470468529"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">3.5 内存(RAM)占用</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_539-1599470468530"></a><span
            style="color:#595959;font-size:12pt">内存占用，即程序运行过程占用的内存资源和是否出现内存泄漏。</span></p>
        <p style="margin-bottom:10pt;margin-top: 10pt;" class="nav-title" id="3-6"><a name="_847-1599470468531"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">3.6 CPU占用</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_611-1599470468533"></a><span
            style="color:#595959;font-size:12pt">CPU占用，即程序运行过程占用的CPU资源。</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_876-1599470668282"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4"><a name="_333-1599470468536"></a><span
            style="color:#409eff;font-size:17pt; font-weight:bold">四、性能测试标准</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_350-1599471042240"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:14pt; line-height:133%; margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-1"><a name="_840-1599470468538"></a><span
            style="color:#595959;font-size:14pt; font-weight:bold">4.1应用启动时间</span></p>
        <p style="font-size:12pt; line-height:133%; margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-1-1"><a name="_635-1599470468539"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.1.1应用冷启动时间</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">应用冷启动完成时间</span></p>
            </td>
          </tr>
          <tr style="height:14pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">记录应用开始启动到进入界面的时间</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过命令“am</span><span
                  style="color:#595959;font-size:11pt">&#xa0;</span><span
                  style="color:#595959;font-size:11pt">force-stop”清除测试应用的进程，进行冷启动测试</span></p>
            </td>
          </tr>
          <tr style="height:89pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准（ms）</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">非游戏应用：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">中高端平台：1500</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">低端平台：3500</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">游戏：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">中高端平台：2000</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">低端平台：4500</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:14pt; line-height:133%; margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-2"><a name="_540-1599470468553"></a><span
            style="color:#595959;font-size:14pt; font-weight:bold">4.2</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">界面帧率</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-1"><a name="_015-1599470468554"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.2.1界面平均帧率</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:8pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">界面滑动过程平均刷新帧率</span></p>
            </td>
          </tr>
          <tr style="height:19pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试应用界面内滑动时的平均帧率</span></p>
            </td>
          </tr>
          <tr style="height:18pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">进入被测界面先滑动等界面所有数据加载完成了再测试</span></p>
            </td>
          </tr>
          <tr style="height:19pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准（FPS）</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">最高帧为目标帧率（需识别出当前界面最高帧），平均帧与目标帧之差不能超过5%，std&lt;5%</span>
              </p>
            </td>
          </tr>
        </table>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-2"><a name="_789-1599470468566"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.2.2界面连续丢帧数</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">界面滑动过程连续丢帧数</span></p>
            </td>
          </tr>
          <tr style="height:26pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">进入被测界面先滑动等界面所有数据加载完成了再测试</span></p>
            </td>
          </tr>
          <tr style="height:26pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">进入被测界面先滑动等数据加载完成了再测试该界面帧率</span></p>
            </td>
          </tr>
          <tr style="height:11pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准(FPS)</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">连续丢帧不能超过3帧</span></p>
            </td>
          </tr>
        </table>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-3"><a name="_742-1599470468579"></a><span
            style="color:#595959;font-size:14pt; font-weight:bold">4.3</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">界面过度绘制</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-3-1"><a name="_035-1599470468580"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.3.1应用界面过度绘制</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:23pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">界面过度绘制</span></p>
            </td>
          </tr>
          <tr style="height:23pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">应用界面未出现过度绘制</span></p>
            </td>
          </tr>
          <tr style="height:23pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">打开开发者选项的“调试GPU过度绘制”</span></p>
            </td>
          </tr>
          <tr style="height:47pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.不允许出现4X过度绘制；</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.不允许存在超过1/3屏幕区域的3X过度绘制</span></p>
            </td>
          </tr>
        </table>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-4"><a name="_787-1599470468592"></a><span
            style="color:#595959;font-size:14pt; font-weight:bold">4.4</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">严格模式检查</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-4-1"><a name="_938-1599470468593"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.4.1严格模式检查</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:24pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">严格模式检查</span></p>
            </td>
          </tr>
          <tr style="height:24pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过严格模式检查应用各界面操作主线程耗时</span></p>
            </td>
          </tr>
          <tr style="height:24pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">打开开发者选项的“严格模式”</span></p>
            </td>
          </tr>
          <tr style="height:24pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">不能出现闪红屏</span></p>
            </td>
          </tr>
        </table>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-5"><a name="_391-1599470468602"></a><span
            style="color:#595959;font-size:14pt; font-weight:bold">4.5</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">内存（RAM）占用</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-5-1"><a name="_511-1599470468603"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.5.1内存泄漏</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:20pt">
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">应用内存泄漏</span></p>
            </td>
          </tr>
          <tr style="height:19pt">
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">压力测试过程监控应用内存占用情况</span></p>
            </td>
          </tr>
          <tr style="height:19pt">
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">无</span></p>
            </td>
          </tr>
          <tr style="height:19pt">
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准（非游戏）</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.内存阈值（PSS峰值）：不超过1.85G</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.未出现内存泄漏</span></p>
            </td>
          </tr>
          <tr style="height:0pt">
            <td style="width:69pt; border:none"></td>
            <td style="width:6pt; border:none"></td>
            <td style="width:245pt; border:none"></td>
          </tr>
        </table>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-5-2"><a name="_676-1599470468628"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.5.2亮屏时后台应用内存占用</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_695-1599474980430"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:25pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">亮屏状态后台应用运行时内存占用</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试应用切换到后台在亮屏状态下应用内存占用</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.测试机器恢复出厂设置</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.应用通过home键切换到后台并保持亮屏</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准</span></p>
            </td>
            <td colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.后台进程数：不超过6个</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.内存占用（PSS均值）：不超过500M</span></p>
            </td>
          </tr>
          <tr style="height:0pt">
            <td style="width:69pt; border:none"></td>
            <td style="width:6pt; border:none"></td>
            <td style="width:245pt; border:none"></td>
          </tr>
        </table>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-5-3"><a name="_145-1599470468642"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.5.3灭屏时后台应用内存占用</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:25pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试内容</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">灭屏状态后台应用运行时内存占用</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试应用切换到后台在灭屏状态下应用内存占用</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.测试机器恢复出厂设置</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.应用通过home键切换到后台并保持灭屏</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.后台进程数：不超过6个</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.内存占用（PSS均值）：不超过400M</span></p>
            </td>
          </tr>
        </table>
        <p style="margin-bottom:10pt"><a name="_384-1599470468643"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-6"><a name="_056-1599474969661"></a><span
            style="color:#595959;font-size:14pt; font-weight:bold">4.6</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959;font-size:14pt; font-weight:bold">CPU占用</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-6-1"><a name="_724-1599470468644"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.6.1亮屏时后台应用CPU占用</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:13pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试亮屏情况下后台应用对CPU的占用情况</span></p>
            </td>
          </tr>
          <tr style="height:14pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试应用切换到后台亮屏状态下应用CPU占用</span></p>
            </td>
          </tr>
          <tr style="height:28pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.测试机器恢复出厂设置</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.应用切换到后台且测试过程一直保持亮屏</span></p>
            </td>
          </tr>
          <tr style="height:8pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">统计10分钟的均值不超过3%</span></p>
            </td>
          </tr>
        </table>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="4-6-2"><a name="_461-1599470468656"></a><span
            style="color:#595959;font-size:12pt; font-weight:bold">4.6.2灭屏时后台应用CPU占用</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:20pt;margin-bottom:10pt">
          <tr style="height:25pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt; font-weight:bold">测试灭屏情况下后台应用对CPU的占用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">测试应用切换到后台灭屏状态下应用CPU占用</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">前提条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">1.测试机器恢复出厂设置</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">2.应用切换到后台且测试过程一直保持灭屏</span></p>
            </td>
          </tr>
          <tr style="height:22pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">通过标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:middle;padding:10pt;">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="color:#595959;font-size:11pt">统计10分钟的均值不超过3%</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_043-1599470468685"></a><span
            style="font-size:11pt">&#xa0;</span></p>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
export default {
  components: { Nav },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 应用性能标准',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,性能,金标认证'
      },
      {
        name: 'description',
        content: '用户对Android应用的性能体验敏感度和要求越来越高，为给用户提供更极致的应用使用体验，本标准旨在构建一套Android应用性能测试衡量标准与方法，牵引生态内所有应用的性能质量改进，共同构建良好的应用生态性能体验。'
      },
    ]
  },
  created() {},
  data() {
    return {
      navFixed: [
          {
            name: "一、 背景",
            isActive:null,
            class:'#1',
          },
          {
            name: "二、 适用范围",
            isActive:null,
            class:'#2',
          },
          {
            name: "三、 术语、定义和缩略语",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 应用冷启动时间",
                isActive:null,
                class:'#3-1',
              },
              {
                name: "3.2 界面帧率",
                isActive:null,
                class:'#3-2',
              },
              {
                name: "3.3 界面过度绘制",
                isActive:null,
                class:'#3-3',
              },
              {
                name: "3.4 严格模式检查",
                isActive:null,
                class:'#3-4',
              },
              {
                name: "3.5 内存(RAM)占用",
                isActive:null,
                class:'#3-5',
              },
              {
                name: "3.6 CPU占用",
                isActive:null,
                class:'#3-6',
              },
            ],
          },
          {
            name: "四、 性能测试标准",
            isActive:null,
            class:'#4',
            child:[
              {
                name: "4.1 应用启动时间",
                isActive:null,
                class:'#4-1',
                child:[
                  {
                    name: "4.1.1 应用冷启动时间",
                    isActive:null,
                    class:'#4-1-1',
                  },
                ]
              },
              {
                name: "4.2 界面帧率",
                isActive:null,
                class:'#4-2',
                child:[
                  {
                    name: "4.2.1 界面平均帧率",
                    isActive:null,
                    class:'#4-2-1',
                  },
                  {
                    name: "4.2.2 界面连续丢帧数",
                    isActive:null,
                    class:'#4-2-2',
                  },
                ],
              },
              {
                name: "4.3 界面过度绘制",
                isActive:null,
                class:'#4-3',
                child:[
                  {
                    name: "4.3.1 应用界面过度绘制",
                    isActive:null,
                    class:'#4-3-1',
                  },
                ],
              },
              {
                name: "4.4 严格模式检查",
                isActive:null,
                class:'#4-4',
                child:[
                  {
                    name: "4.4.1 严格模式检查",
                    isActive:null,
                    class:'#4-4-1',
                  },
                ],
              },
              {
                name: "4.5 内存（RAM）占用",
                isActive:null,
                class:'#4-5',
                child:[
                  {
                    name: "4.5.1 内存泄漏",
                    isActive:null,
                    class:'#4-5-1',
                  },
                  {
                    name: "4.5.2 亮屏时后台应用内存占用",
                    isActive:null,
                    class:'#4-5-2',
                  },
                  {
                    name: "4.5.3 灭屏时后台应用内存占用",
                    isActive:null,
                    class:'#4-5-3',
                  },
                ],
              },
              {
                name: "4.6 CPU占用",
                isActive:null,
                class:'#4-6',
                child:[
                  {
                    name: "4.6.1 亮屏时后台应用CPU占用",
                    isActive:null,
                    class:'#4-6-1',
                  },
                  {
                    name: "4.6.2灭屏时后台应用CPU占用",
                    isActive:null,
                    class:'#4-6-2',
                  },
                ],
              },
            ],
          },
        ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        if(scrollTop > $('.nav-title').eq($(".nav-title").length-2).position().top){
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq($(".nav-title").length-1).css("color","#1989fa");
        }else{
          for(let i=($(".nav-title").length-1);i>=0;i--){
            if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
              $('.nav-fixed-box .name').css("color","#000");
              $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
              this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
              break;
            }
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
}
</style>
