import { render, staticRenderFns } from "./performanceFile.vue?vue&type=template&id=07f87455&scoped=true"
import script from "./performanceFile.vue?vue&type=script&lang=js"
export * from "./performanceFile.vue?vue&type=script&lang=js"
import style0 from "./performanceFile.vue?vue&type=style&index=0&id=07f87455&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f87455",
  null
  
)

export default component.exports